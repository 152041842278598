<template>
  <div class="bg-light">
      <div v-if="posts.length > 0" style="padding-top:10px;">

        <div v-for="post in posts" :key="post">
          <!-- Post -->
          <div v-if="post.post_type == 'post' || post.post_type == 'article'">
            <ArticleCard :post="post" />
          </div>
          <!-- Concert -->
          <div v-if="post.post_type == 'concert'">
            <ArticleCard :post="post" />
          </div>
          <!-- Classified -->
          <div v-if="post.post_type == 'classified'">
            <ArticleCard :post="post" />
          </div>
        </div>
      </div> 
  </div>
</template>

<script>
import { loadingController } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
//import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api'
import ArticleCard from './ArticleCard.vue';

export default defineComponent({
  name: 'PageFeed',
  props: ['page'],
  components: {
    ArticleCard
  },
  setup() {
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const posts = ref([]);
    const timeout = { default: 6000 }
    const pageId = ref(0);

    function getPosts() {
      presentLoading()
      apiClient.get('/api/page/posts/'+pageId.value, {
        headers: {
          'Authorization':  `Bearer ${store.state.authToken}`
        }
      }).then(response => {
        posts.value = response.data

      }).catch(error => {
        console.log(error)  
      });        
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    return {
      authUser, posts, getPosts, pageId
    }

  },
  watch: {
    page: {
      handler() {
        if(this.page) {
          this.pageId = this.page.id
        }
      },
      deep:true
    },
    pageId: function() {
      if(this.pageId > 0) {
        this.getPosts()
      }
    }
  } 
}); 
</script>

<style scoped>


</style>