<template>
  <div>
    <div v-if="page">
      <div class="unofficial ion-padding font-12">
        This page is generated by public information and is not affiliated with {{page.name}}
      </div>
      <div style="padding-top:20px;">
        <!-- Avatar Row -->
        <ion-item lines="none">
          <div tabindex="0"></div>
          <ion-thumbnail v-if="page.image != null && page.image != ''" @click="updateRoute('profile-photo')">
            <img :src="page.image" class="img-avatar w-100 avatar-md" />
          </ion-thumbnail>
          <ion-label style="margin-left:10px;">
            <p style="font-weight:bold;color:#202020;">
              {{page.name}}
            </p>
            <p>
              #{{page.slug}}
            </p>
          </ion-label>
        </ion-item>
      </div>
      <!-- Location -->
      <ion-item v-if="pageLocation != null">
        <div tabindex="0"></div>
        <ion-icon :icon="location" />{{pageLocation}}
      </ion-item>
      <!-- URL -->
      <ion-item v-if="pageLink != null">
        <div tabindex="0"></div>
        <a :href="pageLink" target="_blank" style="text-decoration:none;">{{pageLink}}</a>
      </ion-item>
      <!-- Description -->
      <ion-item v-if="pageDescription != null">
        <div tabindex="0"></div>
        <p>{{pageDescription}}</p>
      </ion-item>
      <!-- Genres -->
      <div v-if="pageGenres.length > 0"  style="padding:15px 10px;">
        <ion-chip @click="goToPage(page.slug)" class="font-12">
          #{{page.slug}}
        </ion-chip>
        <ion-chip @click="goToCategoryPage(categorySlug)" class="font-12">
          #{{categorySlug}}
        </ion-chip>
        <ion-chip v-for="pageGenre in pageGenres" :key="pageGenre" @click="goToGenrePage(pageGenre.slug)" class="font-12">
          #{{pageGenre.slug}}
        </ion-chip>
      </div>
      <!-- No Genres -->
      <div v-else style="padding:15px 10px;">
        <ion-chip @click="goToPage(page.slug)" class="font-12">
          #{{page.slug}}
        </ion-chip>
        <ion-chip @click="goToCategoryPage(categorySlug)" class="font-12">
          #{{categorySlug}}
        </ion-chip>
      </div>
    </div>
  </div>
</template>

<script>
import { IonItem, IonThumbnail, IonLabel, IonIcon, IonChip } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { location } from 'ionicons/icons'
//import { useRouter } from 'vue-router';
//import { useStore } from "vuex";

export default defineComponent({
  name: 'PageHeader',
  props: ['page'],
  components: {
    IonItem, IonThumbnail, IonLabel, IonIcon, IonChip
  },
  setup(props) {
    const pageLocation = ref(null);
    const pageDescription = ref(null);
    const pageGenres = ref([]);
    const pageLink = ref(null);
    const categoryName = ref(null);
    const categorySlug = ref(null);

    function doPageLocation() {
      let tmpCity = null
      let tmpState = null
      let tmpCountry = null
      // City 
      if(isCity() != null) {
        tmpCity = isCity()
      }
      // State 
      if(isState() != null) {
        tmpState = isState()
      }
      // Country 
      if(isCountry() != null) {
        tmpCountry = isCountry()
      }
      // City State and Country
      if(tmpCity != null && tmpState != null && tmpCountry != null) {
        pageLocation.value = tmpCity+', '+tmpState.toUpperCase()+' '+tmpCountry.toUpperCase()
      } 
      // All other combos
      else {
        // City State
        if(tmpCity != null && tmpState != null && tmpCountry == null) {
          pageLocation.value = tmpCity+', '+tmpState.toUpperCase()
        } 
        // City 
        if(tmpCity != null && tmpState == null && tmpCountry == null) {
          pageLocation.value = tmpCity
        } 
        // City Country
        if(tmpCity != null && tmpState == null && tmpCountry != null) {
          pageLocation.value = tmpCity+', '+tmpCountry.toUpperCase()
        }  
        // State Country
        if(tmpCity == null && tmpState != null && tmpCountry != null) {
          pageLocation.value = tmpState.toUpperCase() + ' ' + tmpCountry.toUpperCase()
        }   
        // State
        if(tmpCity == null && tmpState != null && tmpCountry == null) {
          pageLocation.value = tmpState.toUpperCase()
        }          
        // Country
        if(tmpCity == null && tmpState == null && tmpCountry != null) {
          pageLocation.value = tmpCountry.toUpperCase()
        }                
      }
    }

    function isCity() {
      if(props.page.city != null && props.page.city != '') {
        return props.page.city
      } else {
        return null
      }
    }

    function isState() {
      if(props.page.state != null && props.page.state != '') {
        return props.page.state
      } else {
        return null
      }
    }

    function isCountry() {
      if(props.page.country != null && props.page.country != '') {
        return props.page.country
      } else {
        return null
      }
    }

    function doPageDescription() {
      if(props.page.description != null && props.page.description != '') {
        pageDescription.value = props.page.description
      }
      
    }

    function doPageGenres() {
      if(props.page) {
        if(props.page.genres.length > 0) {
          pageGenres.value = props.page.genres
        }
      }
    }

    function doPageMetas() {
      if(props.page) {
        if(props.page.url != null && props.page.url != '') {
          pageLink.value = props.page.url
        }
        if(props.page.category != null && props.page.category != '') {
          categoryName.value = props.page.category.name
          categorySlug.value = props.page.category.slug
        }
      }
    }

    function goToGenrePage(val) {
      window.location = '/genre/' + val
    }

    function goToCategoryPage(val) {
      window.location = '/category/' + val
    }

    function goToPage(val) {
      window.location = '/page/' + val
    }

    return {
      pageLocation, doPageLocation, location, pageDescription, doPageDescription, pageGenres, doPageGenres, goToGenrePage, pageLink, categoryName, categorySlug, doPageMetas, goToCategoryPage, goToPage
    }

  }, 
  watch: {
    page: {
      handler() {
        this.doPageLocation() 
        this.doPageDescription() 
        this.doPageGenres()
        this.doPageMetas()
      },
      deep:true
    }
  }
}); 
</script>

<style scoped>
ion-thumbnail {
   --size: 80px;
}
.theme-light {
  background:#f4f5f8!important;
}
.aw-small-font {
  font-size:12px!important;
}
ion-avatar {
  width:100% !important;
  height: 100% !important;
  max-width: 80px !important;  
  max-height: 80px !important; 
}
.unofficial {
  background:#CCCCCC!important;
}
.font-12 {
  font-size:12px;
}

</style>