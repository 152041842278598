<template>
  <ion-page>
    <!-- Header -->
    <ion-header id="dark-header">
      <div class="app-max-width">
        <ion-toolbar class="text-center">
        
          <ion-buttons slot="start">
            <ion-button @click.prevent="goBack()">
              <ion-icon :icon="chevronBack"></ion-icon>
            </ion-button>
          </ion-buttons>

          <ion-title v-if="pageSlug != null">#{{pageSlug}}</ion-title>
        
        </ion-toolbar>
      </div>
    </ion-header>
    <!-- Page Content -->
    <ion-content>
      <!-- Home Feed -->
      <div class="app-max-width">
        <div v-if="page">
          <!-- Page Header -->
          <PageHeader :page="page" />
          <!-- Page Posts -->
          <PageFeed :page="page" />
        </div>
      </div>
    </ion-content>
    <!-- Footer -->
    <ion-footer>
      <Tabs page="page" />
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, loadingController } from '@ionic/vue';
import { chevronBack, apps, reorderThree, options } from 'ionicons/icons';
import { defineComponent, onMounted, ref, computed } from 'vue';
import doAuth from '../../services/auth';
import Tabs from '../../components/Tabs.vue';
import PageHeader from '../../components/PageHeader.vue';
import PageFeed from '../../components/PageFeed.vue';
import { useRouter } from 'vue-router';
import apiClient from '../../services/api';
import { useStore } from "vuex";

export default defineComponent({
  name: 'PageProfile',
  components: {
    IonPage, IonContent, IonHeader, IonFooter, IonToolbar, IonButtons, IonButton, IonTitle, IonIcon, Tabs, PageHeader, PageFeed
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const router = useRouter();
    const pageSlug = ref(null);
    const page = ref([]);
    const timeout = { default: 6000 }

    onMounted(() => {
      pageSlug.value = router.currentRoute._rawValue.params.slug
        getPage()
    })

    function getPage() {
      presentLoading()

      apiClient.get('/api/get/page/slug/'+pageSlug.value,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          page.value = response.data
        }).catch(error => {
          console.log(error)
        });         
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    function goBack() {
      router.go(-1)
    }

    function updateRoute(val) {
      router.push(val)
    }

    return {
      authUser, router, chevronBack, apps, reorderThree, options, goBack, updateRoute, pageSlug, page
    }
  },
});
</script>

<style scoped>

</style>